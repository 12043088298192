<template>
    <div>
        <v-row dense class="my-2">
            <v-col cols="12" class="d-flex align-center justify-space-between">
                <v-label>
                    {{ label }}
                </v-label>
                <div v-if="htmlDescription" slot="append">
                    <v-tooltip left :open-on-hover="false">
                        <template #activator="{ on }">
                            <v-btn
                                icon
                                @click="on.click"
                                @blur="on.blur"
                                retain-focus-on-click
                            >
                                <v-icon>mdi-information</v-icon>
                            </v-btn>
                        </template>
                        <div class="vjsf-tooltip" v-html="htmlDescription" />
                    </v-tooltip>
                </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-start">
                <div class="d-flex flex-column align-center">
                    <div>
                        <div class="d-flex justify-space-between">
                            <v-icon>{{ xConfig.prependIcon }}</v-icon>
                            <v-rating
                                :value="value"
                                v-on="{ ...on, input }"
                                :empty-icon="xConfig.emptyIcon"
                                :full-icon="xConfig.fullIcon"
                                class="px-4"
                                color="primary"
                                :background-color="
                                    isValidationError ? 'error' : 'primary'
                                "
                                x-large
                            />
                            <v-icon>{{ xConfig.appendIcon }}</v-icon>
                        </div>
                        <div
                            :class="{ ['error--text']: isValidationError }"
                            class="d-flex justify-space-between px-4 pb-2"
                        >
                            <div class="text-body-2">
                                {{ xConfig.prependText }}
                            </div>
                            <div class="text-body-2">
                                {{ xConfig.appendText }}
                            </div>
                        </div>
                        <v-messages
                            v-if="hasMessages"
                            color="error"
                            :value="messagesToDisplay"
                        />
                    </div>
                </div>
            </v-col>
            <v-col v-if="xConfig.dismissable" cols="12" class="ml-4">
                <v-switch
                    :value="!value"
                    @change="onDismissed"
                    dense
                    :label="xConfig.dismissable.title"
                    hide-details
                    :error="validationState === 'error'"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import VInput from 'vuetify/es5/components/VInput'
export default {
    extends: VInput,
    name: 'RatingScale',
    props: {
        value: { type: Number },
        options: { type: Object, required: true },
        schema: { type: Object, required: true },
        fullSchema: { type: Object, required: true },
        fullKey: { type: String, required: true },
        label: { type: String, default: '' },
        htmlDescription: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        rules: { type: Array, required: true },
        on: { type: Object, required: true },
    },
    methods: {
        input(value) {
            this.on.input(value)
        },
        onDismissed(value) {
            if (value) {
                this.on.input(0)
            } else {
                this.on.input(undefined)
            }
        },
    },
    computed: {
        xConfig() {
            return this.schema['x-config']
        },
        isValidationError() {
            return this.validationState === 'error'
        },
    },
}
</script>

<style scoped>
.v-input .v-label {
    height: auto;
}
</style>
